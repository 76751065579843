export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
import Headers from '../headers';

export const BeneficiaryService = {
    fetchBeneficiaryTable,
    fetchBeneficiaryTableMasterMerchant,
    createBeneficiary,
    updateBeneficiary,
    updateBeneficiaryAccount,
    fetchBanksList
};

function fetchBeneficiaryTable(key, payload) {
    payload.pageIndex = payload.pageIndex || 1;
    payload.pagesize = payload.pagesize || 100;
    const requestOptions = {
        method: 'POST',
        headers: Headers.apiHeaders(),
        body: JSON.stringify(payload),
        credentials: 'include',
    };
    // const queryParams = new URLSearchParams(payload);
    return fetch(`${CHAIPAY_API_URL}/merchants/${key}/payout/beneficiaries`, requestOptions).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.text().then(text => {
                if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
                    window.location = window.location.origin + '/logout';
                }
                throw new Error(text);
            });
        }
    });
}

function fetchBeneficiaryTableMasterMerchant(key, payload) {
    const requestOptions = {
        method: 'POST',
        headers: Headers.apiHeaders(),
        body: JSON.stringify(payload),
        credentials: 'include',
    };

    return fetch(`${CHAIPAY_API_URL}/master-merchant/${key}/merchants/payouts/beneficiaries`, requestOptions).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.text().then(text => {
                if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
                    window.location = window.location.origin + '/logout';
                }
                throw new Error(text);
            });
        }
    });
}


function fetchBanksList(iamportKey, channelKey, environment) {
    const requestOptions = {
        method: 'GET',
        headers: Headers.apiHeaders(),
        body: JSON.stringify(),
        credentials: 'include',
    };
    const queryParams = new URLSearchParams({channelKey, environment, merchantKey: iamportKey });
    return fetch(`${CHAIPAY_API_URL}/merchants/payout/supported-banks?` + queryParams, requestOptions).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.text().then(text => {
                if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
                    window.location = window.location.origin + '/logout';
                }
                throw new Error(text);
            });
        }
    });
}


function createBeneficiary(key, newBeneficiary) {
    const requestOptions = {
        method: 'POST',
        headers: Headers.apiHeaders(),
        body: JSON.stringify(newBeneficiary),
        credentials: 'include',
    };
    return fetch(`${CHAIPAY_API_URL}/merchants/${key}/payout/createBeneficiary`, requestOptions).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.text().then(text => {
                if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
                    window.location = window.location.origin + '/logout';
                }
                throw new Error(text);
            });
        }
    });
}

function updateBeneficiary(key, payload) {
    const requestOptions = {
        method: 'POST',
        headers: Headers.apiHeaders(),
        body: JSON.stringify(payload),
        credentials: 'include',
    };
    return fetch(`${CHAIPAY_API_URL}/merchants/${key}/payout/editBeneficiary`, requestOptions).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.text().then(text => {
                if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
                    window.location = window.location.origin + '/logout';
                }
                throw new Error(text);
            });
        }
    });
}

function updateBeneficiaryAccount(merchantUUID, {uuid, key, addAccount}) {
    const requestOptions = {
        method: 'PUT',
        headers: Headers.apiHeaders(),
        body: JSON.stringify(addAccount),
        credentials: 'include',
    };
    return fetch(`${CHAIPAY_API_URL}/merchants/${key}/payout/vendors/${uuid}/bank-accounts`, requestOptions).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.text().then(text => {
                if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
                    window.location = window.location.origin + '/logout';
                }
                throw new Error(text);
            });
        }
    });
}
